/* .custom_checkbox{
    border:2px solid #016afe !important;
  } */
label {
  position: relative;
  cursor: pointer;
  transition: all .2s ease-in-out;
}

label::after {
  position: absolute;
  content: '';
  height: 22px;
  width: 22px;
  top: 8px;
  left: 8px;
  display: none;
  border: 1px solid #551ffa;
  color: white;
  text-align: center;
  line-height: 18px;
  font-weight: 600;
  font-size: 17px;
  border-radius: 50%;
  /* z-index: 1000000000000;  */
  /* display: flex; */
  justify-content: center;
  align-items: center;
}

input:checked+label::after {
  content: '\2713';
  position: absolute;
  height: 22px;
  width: 22px;
  color: white;
  top: 8px;
  left: 8px;
  background-color: #551ffa;
  color: white;
  text-align: center;
  line-height: 18px;
  font-weight: 600;
  font-size: 17px;
  border-radius: 50%;
  z-index: 1000000000000;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* input:checked + .custom_checkbox{
  border:2px solid #551ffa !important;
  } */
.checkboxlabel {
  position: absolute;
  height: 0vh;
  width: 100%;
  /* border:1px solid black; */
  transition: all .5s ease;
  /*border-top-left-radius: 10px;*/
  /*border-top-right-radius: 10px;*/
  /* transform: translateY(-5px); */
  /* display: none; */
}

.card--container:hover .checkboxlabel {
  /* border:1px solid orange; */
  z-index: 100000000;
  height: 5vh;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: rgba(255, 255, 255, .87)
}

.card--container:hover .checkboxlabel::after {
  display: flex;
}