.App {
  box-sizing: border-box;
}

/* .App::-webkit-scr {
} */
html {
  overflow-y: auto;
  overflow-x: hidden;
}

.main-scrollbar::-webkit-scrollbar {
  width: 5px !important;
  /*background-color: lightgray !important;*/
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.hidden-rm {
  display: none !important;
}

.show-rm {
  display: flex !important;
}

.file-paggination-box {
  min-height: 5vh;
  width: 100%;
  /* // border:'1px solid black', */
  display: flex;
}

.file-btn-group-box {
  min-height: 5vh;
  width: 100%;
  /* // border:'1px solid black', */
  display: none;
}

.screen-paggination-box {
  min-height: 7vh;
  width: 100%;
  /* // border:'1px solid black', */
  display: flex;
}

.screen-btn-group-box {
  min-height: 7vh;
  width: 100%;
  /* // border:'1px solid black', */
  display: none;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.justifyContentCenter {
  justify-content: center;
}

.alignItemCenter {
  align-items: center;
}

.flex-content-space-around {
  justify-content: space-around;
}

.flex-content-space-between {
  justify-content: space-between;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.checkbox {
  height: 19px;
  width: 19px;
  border-radius: 19px;
  background-color: #fff;
  cursor: pointer;
}

.checkbox:hover {
  background-color: #fff;
  opacity: 1;
}

.checkbox-muted {
  opacity: 0.3;
}

.checkbox-mid {
  margin-left: 10px;
  margin-right: 10px;
}

.title-color {
  font-family: Open Sans !important;
  font-style: normal;
  color: #15192c;
  font-weight: 800 !important;
  font-size: 25px;
  line-height: 54px !important;
  letter-spacing: -0.02em !important;
}

@media screen and (max-width: 320px) {
  .title-color {
    font-size: 10px !important;

  }
}

/* its media query for 280 in main rightside nav profile */

@media screen and (max-width: 280px) {
  .profile-right-div {
    margin-left: 100px !important;

  }
}

.date-time {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 15px !important;
  line-height: 20px;
  color: #4f4f4f;
  margin-left: 9px !important;
}

.calender-icon {
  height: 16px;
  width: 16px;
  object-fit: contain;
  transform: translateX(-1px);
}

.screen-img {
  width: 40%;
  height: 40%;
  object-fit: contain;
}

.screen-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 20px !important;
}

.open-btn {
  width: 45%;
  height: 4.4vh;
  background: #016afe;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  color: #fff;
  transform: translateY(-7%);
}

.t-head {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: 8vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.t-head .td:first-child {
  width: 28%;
  text-align: left;
  padding-left: 10%;
}

.t-head .td {
  width: 18%;
  text-align: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  /* border: 1px solid green; */
  color: #15192c;
}

.t-head .td .more-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 117px;
  height: 38px;
  background: #016afe;
  border-radius: 10px;
  border: none;
  outline: none;
  color: #fff;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
}

.t-body {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.t-body .tr {
  height: 8vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.t-body .td:first-child {
  width: 28%;
  text-align: left;
  padding-left: 10%;
}

.t-body .td {
  width: 18%;
  text-align: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #15192c;
}

.t-body .td.light {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #15192c;
}

.t-body .td .online {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 110px;
  height: 40px;
  background: #d3ffe0;
  border: none;
  outline: none;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #15192c;
  text-transform: capitalize;
}

.t-body .td .offline {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 110px;
  height: 40px;
  background: #ffd3d3;
  border: none;
  outline: none;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #15192c;
  text-transform: capitalize;
}

.daily-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 33px !important;
  letter-spacing: -0.02em;
  color: #15192c;
}

.daily-text-1 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 33px !important;
  letter-spacing: -0.02em;
  color: #15192c;
}

.last-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  align-items: center;
  display: flex;
  transform: translateX(-5%);
  color: #92959e;
}

.increase {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #92959e !important;
  position: absolute;
  bottom: 25px;
}

.increase span {
  color: #016afe;
}

.onlines {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 60px;
  height: 20px;
  background: #d3ffe0;
  border: none;
  outline: none;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: -0.02em;
  color: #15192c;
  text-transform: capitalize;
  transform: translateX(10px);
}

.offlines {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 60px;
  height: 20px;
  background: #ffd3d3;
  border: none;
  outline: none;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: -0.02em;
  color: #15192c;
  text-transform: capitalize;

  transform: translateX(10px);
}

@media (max-width: 786px) {
  .t-body .td:first-child {
    width: 28%;
    text-align: left;
    padding-left: 3%;
    display: contents;
  }

  .t-head .td:first-child {
    width: 28%;
    text-align: left;
    padding-left: 3%;
  }

  .title-color {
    /* font-size: 22px !important; */
    line-height: 22px !important;
  }

  .date-time {
    font-size: 11px !important;
    line-height: 11px !important;
    margin-left: 2px !important;
  }


}

.apexcharts-toolbar {
  display: none !important;
}

.screen-imp {
  position: absolute;
  left: 12%;
  top: 10%;
}

.last-text-2 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  align-items: center;
  display: flex;
  color: #92959e;
}

.lets {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bolder !important;
  font-size: 28px !important;
  line-height: 41px !important;
  letter-spacing: -0.02em;
  color: #15192c;
}

.upload-btn {
  width: 366px;
  height: 40px;
  border: none;
  outline: none;
  background: #016afe;
  border-radius: 5px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #ffffff;
}

.lorem-file {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px !important;
  line-height: 18px !important;
  color: #92959e;
}

.mid-btn {
  width: 48%;
  height: 40px;
  border: none;
  outline: none;
  background: #016afe;
  border-radius: 5px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #ffffff;
}

.type-file {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 20px !important;
  color: #92959e;
  margin-top: 20px !important;
}

.drag-drop {
  margin-top: 20px;
  width: 90%;
  height: 62px;
  border: 1px dashed #016afe;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 33px !important;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: rgba(21, 25, 44, 0.2) !important;
  justify-content: center;

}

.dragging-border {
  border: 2px solid #551ffa !important;
  display: none;
}

.input-focus:focus {
  border: none;
}

/* 
nouman work
*/
.arrowRotate {
  transform: rotate(90deg);
}

.textPreview {
  position: absolute;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  animation: linear infinite;
}

@keyframes horizontal {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes horizontalreverse {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.custom-input {
  width: 396px;
  height: 35px;
  padding: 0 12px;
  /* border: none; */
  /* border-bottom: 2px solid blue; */
  font-size: medium;
  border-radius: 5px;
  border-color: #a9a9a9;
}

/* nouman work ending */
/* 
.App {
  box-sizing: border-box;
}
.App::-webkit-scr {
}
html{
  overflow-y: auto;
  overflow-x: hidden;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.checkbox {
  height: 19px;
  width: 19px;
  border-radius: 19px;
  background-color: #fff;
  cursor: pointer;
}

.checkbox:hover {
  background-color: #fff;
  opacity: 1;
}

.checkbox-muted {
  opacity: 0.3;
}

.checkbox-mid {
  margin-left: 10px;
  margin-right: 10px;
}

.title-color {
  font-family: Open Sans !important;
  font-style: normal;
  color: #15192c;
  font-weight: 800 !important;
  font-size: 25px !important;
  line-height: 54px !important;
  letter-spacing: -0.02em !important;
}

.date-time {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 15px !important;
  line-height: 20px;
  color: #4f4f4f;
  margin-left: 9px !important;
}

.calender-icon {
  height: 16px;
  width: 16px;
  object-fit: contain;
  transform: translateX(-1px);
}

.screen-img {
  width: 40%;
  height: 40%;
  object-fit: contain;
}

.screen-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 20px !important;
}

.open-btn {
  width: 45%;
  height: 4.4vh;
  background: #016afe;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  color: #fff;
  transform: translateY(-7%);
}

.t-head {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: 8vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.t-head .td:first-child {
  width: 28%;
  text-align: left;
  padding-left: 10%;
}

.t-head .td {
  width: 18%;
  text-align: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;

  color: #15192c;
}

.t-head .td .more-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 117px;
  height: 38px;
  background: #016afe;
  border-radius: 10px;
  border: none;
  outline: none;
  color: #fff;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
}

.t-body {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.t-body .tr {
  height: 8vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.t-body .td:first-child {
  width: 28%;
  text-align: left;
  padding-left: 10%;
}

.t-body .td {
  width: 18%;
  text-align: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;

  color: #15192c;
}

.t-body .td.light {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #15192c;
}

.t-body .td .online {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 110px;
  height: 40px;
  background: #d3ffe0;
  border: none;
  outline: none;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #15192c;
  text-transform: capitalize;
}

.t-body .td .offline {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 110px;
  height: 40px;
  background: #ffd3d3;
  border: none;
  outline: none;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #15192c;
  text-transform: capitalize;
}

.daily-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 33px !important;
  letter-spacing: -0.02em;
  color: #15192c;
}
.daily-text-1 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 33px !important;
  letter-spacing: -0.02em;
  color: #15192c;
}

.last-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  align-items: center;
  display: flex;
  transform: translateX(-5%);
  color: #92959e;
}

.increase {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #92959e !important;
  position: absolute;
  bottom: 25px;
}

.increase span {
  color: #016afe;
}

@media (max-width: 786px) {
  .t-body .td:first-child {
    width: 28%;
    text-align: left;
    padding-left: 3%;
    display: contents;
  }
  .t-head .td:first-child {
    width: 28%;
    text-align: left;
    padding-left: 3%;
  }
  .title-color {
    font-size: 22px !important;
    line-height: 22px !important;
  }
  .date-time {
    font-size: 11px !important;
    line-height: 11px !important;
    margin-left: 2px !important;
  }

  .t-body .td .online {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 60px;
    height: 20px;
    background: #d3ffe0;
    border: none;
    outline: none;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 12px;
    letter-spacing: -0.02em;
    color: #15192c;
    text-transform: capitalize;
    transform: translateX(10px);
  }

  .t-body .td .offline {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 60px;
    height: 20px;
    background: #ffd3d3;
    border: none;
    outline: none;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 12px;
    letter-spacing: -0.02em;
    color: #15192c;
    text-transform: capitalize;

    transform: translateX(10px);
  }
}

.apexcharts-toolbar {
  display: none !important;
}

.screen-imp {
  position: absolute;
  left: 12%;
  top: 10%;
}

.last-text-2 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  align-items: center;
  display: flex;
  color: #92959e;
}

.lets {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bolder !important;
  font-size: 28px !important;
  line-height: 41px !important;
  letter-spacing: -0.02em;
  color: #15192c;
}

.upload-btn {
  width: 366px;
  height: 40px;
  border: none;
  outline: none;
  background: #016afe;
  border-radius: 10px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #ffffff;
}

.lorem-file {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px !important;
  line-height: 18px !important;
  color: #92959e;
}

.mid-btn {
  width: 48%;
  height: 40px;
  border: none;
  outline: none;
  background: #016afe;
  border-radius: 10px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #ffffff;
}

.type-file {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 20px !important;
  color: #92959e;
  margin-top: 20px !important;
}

.drag-drop {
  margin-top: 20px;
  width: 90%;
  height: 62px;
  border: 1px dashed #016afe;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 33px !important;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: rgba(21, 25, 44, 0.2) !important;
justify-content: center;

}
.dragging-border{
  border:2px solid #551ffa !important;
  display: none;
}
.input-focus:focus{
  border:none;
} */

/*Fieldset RESET*/
legend {
  padding: 0;
  display: table;
}

fieldset {
  border: 0;
  padding: 0.01em 0 0 0;
  margin: 0;
  min-width: 0;
}

body:not(:-moz-handler-blocked) fieldset {
  display: table-cell;
}

.pac-container {
  z-index: 9999;
}